<template>
  <div class="reviews-stars flex">
    <template v-for="(i, index) in bestRating">
    <div
        v-if="index < ~~rating || (index < rating && index)"
        :key="index"
        class="reviews-stars__star relative"
        :class="{ 'mr-0.5 xxs:mr-2' : spacing}"
      >
        <img
          v-if="index < ~~rating"
          :src="`/_nuxt_home_page/icons/${iconName.star_full}`"
          alt="full star"
          width="20"
          height="20"
          loading="lazy"
        />
        <img
          v-if="index < rating && index === ~~rating"
          :src="`/_nuxt_home_page/icons/${iconName.star_half}`"
          alt="half star"
          width="20"
          height="20"
          loading="lazy"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    bestRating: {
      type: Number,
      default: () => 5,
    },
    rating: {
      type: Number,
      default: () => Math.PI,
      required: true,
    },
    small: {
      type: Boolean,
      defaul: () => false,
      required: false,
    },
    hideOverRating: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    iconName: {
      type: Object,
      default: {
        star_full: 'star-full.svg',
        star_half: 'star-half.svg',
      },
    },
    spacing: {
      type: Boolean,
      default: () => true,
      required: false,
    },
  },
};
</script>
