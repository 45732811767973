import { getInstance } from '~/services/bffService';

export const state = () => ({
  languages: [],
  language: {},
  airports: [],
  inactiveAirports: [],
  airport: {},
  airportData: {},
  totalParkings: 0,
  reviews: [],
  reviewsMeta: {},
  translations: {},
  pageContent: {},
  modalOpen: false,
  trustpilotUrls: {},
  headerAirportsConfiguration: {},
  flags: {
    experiments: {},
    features: {},
  },
});

export const mutations = {
  setModal(state, value) {
    state.modalOpen = value;
  },
};

function assignDataForDABucket(state, bucketState) {
  // domain
  bucketState.domain.country = state.language.country.name;
  bucketState.domain.country_code = state.language.country.code;
  bucketState.domain.language = state.language.lang;
  bucketState.domain.name = state.language.domain;
  //
  bucketState.airport.name = state.airport?.name;
  bucketState.airport.id = state.airport?.id;
  bucketState.airport.slug = state.airport?.slug;
  bucketState.airport.devtitle = state.airport?.devtitle;
  bucketState.airport.commonName = state.airport?.commonName;
  bucketState.airport.internationalAirportName = state.airport?.internationalAirportName;
  //
  bucketState.review_score = state.reviewsMeta?.reviews?.score;
  bucketState.review_amount = state.reviewsMeta?.reviews?.count;
  //
  bucketState.currency = state.language.currency.iso_code;
}

export const getters = {
  isExperimentEnabled: (state) => (name) => {
    const { features } = state.flags;

    return !!features?.[name];
  },
  experimentVariant: (state) => (name) => state.flags.features?.[name],
};

export const actions = {
  experimentVariant({ state, commit }, { name = '', condition = true }) {
    if (!condition) {
      return undefined;
    }
    commit('experiments/addExperiment', {
      name,
      variant: state.flags.features?.[name],
    });
    return state.flags.features?.[name];
  },
  setExperiments({ state }, req) {
    try {
      if (req.headers?.['x-features']) {
        state.flags.features = JSON.parse(req.headers['x-features']);
      }

      if (req.headers?.['x-experiments']) {
        const result = {};
        req.headers?.['x-experiments'].split(',')
          .forEach((element) => {
            const [elKey, elValue] = element.split('=');
            result[elKey] = elValue;
          });
        state.flags.experiments = result;
      }
    } catch (error) {
      console.error(error);
    }
  },
  async nuxtServerInit({ state, dispatch }, { app, req }) {
    console.log('[nuxtServerInit] start ', req.originalUrl);
    const bff = getInstance('parkos');

    dispatch('setExperiments', req);

    try {
      if (req.headers?.['x-features']) {
        state.flags.features = JSON.parse(req.headers['x-features']);
      }

      if (req.headers?.['x-experiments']) {
        const result = {};
        req.headers?.['x-experiments'].split(',').forEach((element) => {
          const [elKey, elValue] = element.split('=');
          result[elKey] = elValue;
        });
        state.flags.experiments = result;
      }
    } catch (error) {
      console.error(error);
    }
    state.languages = await bff.getLanguages();
    state.language = state.languages.find(
      (language) => language.domain === app.$paths.host,
    );
    state.languages = state.languages.map((lang) => ({
      ...lang,
      domain: `${app.$paths.urlEnvPrefix}${lang.domain}`,
    }));

    const airports = await bff.getAirports(state.language?.lang ?? 'en-us');
    state.airports = airports.filter(
      (airport) => !!state.language?.status
        && (!!state.language.is_minimal_product || airport.parking_count > 0),
    );
    state.inactiveAirports = airports.filter(
      (a) => !state.airports.map((b) => b.id).includes(a.id),
    );

    state.airport = {};

    const { lang } = state.language;

    if (!state.language.socials.facebook) {
      delete state.language.socials.facebook;
    }

    if (!state.language.socials.twitter) {
      delete state.language.socials.twitter;
    }

    // If no YouTube link is set in the socials fallback to the default
    if (!state.language.socials.youtube) {
      state.language.socials.youtube = 'https://www.youtube.com/parkosnl';
    }

    const promises = [
      bff.getCountryStatistics(state.language.id),
      bff.getAirportReviews(undefined, state.language.lang || 'en-us'),
      bff.getTranslations(state.language.lang),
      ...(['home', 'terms-conditions', 'privacy-policy', 'about-us', 'faq'].map((slug) => (bff.getPageContent(slug)))),
      bff.getService('trustpilot'),
      bff.getHeaderAirportsConfiguration(),
      ...(['footer_airports', 'footer_information', 'footer_about', 'footer_icons'].map((slug) => (bff.getPageTemplate(slug, state.language.lang)))),
      bff.getTop3Air(state.language.lang),
    ];
    const [
      countryStatistics,
      airportReviews,
      translations,
      homeContent,
      termsContent,
      privacyPolicyContent,
      aboutContent,
      faqMeta,
      trustpilotUrls,
      headerAirportsConfiguration,
      airportsTemplate,
      informationTemplate,
      aboutTemplate,
      paymentIconsTemplate,
      top3Air,
    ] = await Promise.all(promises);

    state.totalParkings = countryStatistics?.data.merchants.total;

    const reviewData = airportReviews;

    state.reviews = reviewData.data[state.language.lang].sort((a, b) => (a.content.length > b.content.length ? -1 : 1));
    state.reviewsMeta = reviewData.meta;
    state.translations = translations;

    state.pageContent = {
      home: homeContent[lang],
      termsConditionsContent: termsContent[lang],
      privacyPolicyContent:
        privacyPolicyContent[lang],
      aboutPageContent: aboutContent[lang],
    };

    state.trustpilotUrls = trustpilotUrls;
    state.modalOpen = false;

    state.headerAirportsConfiguration = headerAirportsConfiguration;

    state.pageTemplate = {
      footer_airports: airportsTemplate,
      footer_information: informationTemplate,
      footer_about: aboutTemplate,
      footer_icons: paymentIconsTemplate,
    };
    state.metaPages = {
      faqMeta:
        faqMeta[state.language.lang] || undefined,
    };
    state.top3Air = top3Air;

    assignDataForDABucket(state, state.bucket);
    console.log('[nuxtServerInit] end ', req.originalUrl);
  },

};

export const strict = false;
